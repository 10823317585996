<template>
  <div class="">
      123213
    <div class="echart" ref="chartDom" id="chartDomId"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { ref, onMounted,reactive } from "vue";
export default {
  setup() {
    const chartDom = ref(null);
    let myChart = null;
    let option = reactive({
      title: { text: "总用户量" },
      tooltip: {},
      xAxis: {
        data: ["12-3", "12-4", "12-5", "12-6", "12-7", "12-8"],
      },
      yAxis: {},
      series: [
        {
          name: "用户量",
          type: "line",
          data: [5, 20, 36, 10, 50, 20],
        },
      ],
    });

    onMounted(() => {
      myChart = echarts.init(chartDom.value);
      //myChart = echarts.init(document.querySelector("#chartDomId"));
      myChart.setOption(option, true);
    });
    return {
      chartDom,
      myChart,
    };
  },
  name: "",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>

.echart {
    width: 800px;
    height: 500px;
}
</style>